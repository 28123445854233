export const WRITABLE_LEARNER_ACTIONS = [
  'editLearner',
  'deleteLearners',
  'markLearnersActive',
  'markLearnersInactive',
  'addLearnersToGroup',
  'enrollOnCourse',
  'enrollOnGapAnalysis',
  'unenrolFromCourse',
  'unenrolGapAnalysis',
  'sendPolicy',
  'signPolicy',
  'removeLearnersFromPolicy',
  'changeLanguage',
  'detachLearnersFromSync',
  'restoreUsers'
]

export const WRITABLE_LEARNER_COURSE_ACTIONS = [
  'sendReminder',
  'unenrolCourse'
]

export const WRITABLE_LEARNER_GAP_ANALYSIS_ACTIONS = [
  'sendGapAnalysisReminder',
  'unenrolGapAnalysis'
]

export const WRITABLE_SIMULATION_ACTIONS = [
  'cloneSimulation',
  'deleteSimulation',
  'sendTestEmail',
  'sendEmails',
  'editSimulation',
  'detachFromRiskReport',
  'attachToRiskReport',
  'deleteSimulations'
]

export const WRITABLE_POLICY_ACTIONS = [
  'editPolicy',
  'editPolicyDraft',
  'discardPolicyDraft',
  'publishPolicyDraft',
  'duplicatePolicy',
  'deletePolicy',
  'uploadPolicySignatures',
  'sendPolicy'
]

export const WRITABLE_EMAIL_TEMPLATE_ACTIONS = [
  'edit',
  'sendTest',
  'clone',
  'addLanguages',
  'removeLanguages',
  'delete'
]

export const WRITABLE_LANDING_PAGE_BUILDER_ACTIONS = [
  'edit',
  'clone',
  'addLanguages',
  'removeLanguages',
  'delete'
]

export const WRITABLE_COURSE_ACTIONS = [
  'editCourse',
  'editSlides',
  'duplicateCourse',
  'deleteCourse',
  'translateCourse'
]

export const WRITABLE_COURSE_CARD_ACTIONS = [
  'enrol',
  'bulk-enrol',
  'duplicateCourse'
]

export const WRITABLE_LIBRARY_CARD_ACTIONS = [
  'clone'
]

export const WRITABLE_SCHEDULED_REPORT_ACTIONS = [
  'editScheduledReport',
  'deleteScheduledReport'
]

export const WRITABLE_REPORT_MANAGER_ACTIONS = [
  'update',
  'delete'
]

export const WRITABLE_UBREACH_ACTIONS = [
  'markResolved'
]
