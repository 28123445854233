import React from 'react'
import I18n from 'i18n-js'
import _isArray from 'lodash/isArray'
import _isEmpty from 'lodash/isEmpty'

import SeverityField from '../components/uBreach/SeverityField'
import { renderDataClasses } from '../components/uBreach/BreachesTable'
import { formatDate } from './datetime'
import { stripHTML } from './sanitise'

import {
  transformCardData,
  transformPieChartData
} from './reports'

import theme from '../theme/themes'

const getSummaryCard = ({ title, value, tooltip, printScale }) => ({
  title,
  ...transformCardData({
    withIcon: false,
    withPrevious: false,
    tooltip,
    printScale,
    value
  })
})

const getBreachReportBreachesSectionComponent = ({ trOpt, data, type, section }) => {
  const printScale = 1.2
  const cardData = [
    {
      title: I18n.t('common.cards.usersAffected.title', trOpt),
      tooltip: I18n.t('common.cards.usersAffected.tooltip', trOpt),
      printScale,
      value: data.affected || 0
    },
    {
      title: I18n.t('common.cards.breachedServices.title', trOpt),
      tooltip: I18n.t('common.cards.breachedServices.tooltip', trOpt),
      printScale,
      value: data.breachedServices || 0
    },
    {
      title: I18n.t('common.cards.usersBreached.title', trOpt),
      tooltip: I18n.t('common.cards.usersBreached.tooltip', trOpt),
      printScale,
      value: data.breaches?.total || 0
    },
    {
      title: I18n.t('common.cards.userBreachesResolved.title', trOpt),
      tooltip: I18n.t('common.cards.userBreachesResolved.tooltip', trOpt),
      printScale,
      value: data.breachesResolved || 0
    }
  ]

  const values = {
    low: data.breaches?.lowSeverity || 0,
    medium: data.breaches?.mediumSeverity || 0,
    high: data.breaches?.highSeverity || 0
  }

  const breachesTotal = data.breaches?.total || 0

  return {
    title: I18n.t(`${section}.${type}.title`, trOpt),
    text: I18n.t(`${section}.${type}.description`, trOpt),
    graph: {
      title: I18n.t('common.graphs.severityBreakdown.title', trOpt),
      description: I18n.t('common.graphs.severityBreakdown.description', trOpt),
      ...transformPieChartData(
        {
          data: [
            {
              id: 'high',
              value: breachesTotal ? Math.round((values.high / breachesTotal) * 100) : 0
            },
            {
              id: 'medium',
              value: breachesTotal ? Math.round((values.medium / breachesTotal) * 100) : 0
            },
            {
              id: 'low',
              value: breachesTotal ? Math.round((values.low / breachesTotal) * 100) : 0
            }
          ],
          formatLabel: ({ id, value }) => I18n.t(id, { scope: 'reports.breachReport.common.graphs.severityBreakdown.labels', percentage: value, value: values[id] }),
          trOpt: { scope: 'reports.breachReport.common.graphs.severityBreakdown.hover' }
        }
      ),
      options: {
        colors: [theme.main.red, theme.main.orange, theme.main.primary],
        valueFormat: value => `${value}%`,
        printScale: 2
      }
    },
    cards: cardData.map((card) => getSummaryCard(card)),
    chartContentWidth: '40%',
    cardDescriptionWidth: '60%'
  }
}

const getBreachReportBreachesSection = ({ trOpt, data, section }) => ({
  header: I18n.t(`${section}.title`, trOpt),
  headerText: I18n.t(`${section}.description`, trOpt),
  components: [
    getBreachReportBreachesSectionComponent({
      trOpt,
      data: data.overall,
      type: 'overall',
      section
    }),
    getBreachReportBreachesSectionComponent({
      trOpt,
      data: data.thisPeriod,
      type: 'thisPeriod',
      section
    })
  ]
})

const getBreachedServiceComponent = ({
  trOpt,
  data: {
    title,
    description,
    dataClasses,
    addedDate,
    severity,
    learners,
    domainEmails
  }
}) => {
  const cardData = [
    {
      title: I18n.t('common.cards.usersAffected.title', trOpt),
      value: learners?.affected || 0
    },
    {
      title: I18n.t('common.cards.nonSystemUsersAffected.title', trOpt),
      value: domainEmails?.affected || 0
    },
    {
      title: I18n.t('common.cards.usersResolved.title', trOpt),
      value: learners?.resolved || 0
    },
    {
      title: I18n.t('common.cards.nonSystemUsersResolved.title', trOpt),
      value: domainEmails?.resolved || 0
    }
  ]

  return {
    title: title,
    text: (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ display: 'flex', gap: 5, flexGrow: 1 }}>
            <div>{I18n.t('common.addedOn', trOpt)}</div>
            <div>{formatDate(addedDate)}</div>
          </div>
          <div style={{ display: 'flex', gap: 5, flexGrow: 1 }}>
            <div>{I18n.t('common.severity', trOpt)}</div>
            <SeverityField severity={severity} />
          </div>
        </div>
        <div>{stripHTML(description)}</div>
      </div>
    ),
    useFixedColumnLayout: true,
    useFullWidth: true,
    graph: {
      title: I18n.t('common.graphs.exposedInformation.title', trOpt),
      type: 'custom',
      component: <div>{renderDataClasses(dataClasses)}</div>
    },
    cards: cardData.map((card) => getSummaryCard(card))
  }
}

const getBreachedServiceSection = ({ section: sectionId, numColumns = 2, services, trOpt }) => {
  const section = {
    header: I18n.t(`${sectionId}.title`, trOpt),
    headerText: I18n.t(`${sectionId}.description`, trOpt),
    numColumns
  }
  if (_isArray(services) && !_isEmpty(services)) {
    section.components = services.map(data => getBreachedServiceComponent({ trOpt, data }))
  } else {
    section.numColumns = 1
    section.components = [{
      key: `${sectionId}_noData`,
      noData: true
    }]
  }

  return section
}

export const getBreachReportSections = ({
  breachReport,
  trOpt
}) => ([
  getBreachReportBreachesSection({
    trOpt,
    data: {
      overall: breachReport.learnersOverall,
      thisPeriod: breachReport.learnersThisPeriod
    },
    section: 'userBreaches'
  }),
  getBreachReportBreachesSection({
    trOpt,
    data: {
      overall: breachReport.domainEmailsOverall,
      thisPeriod: breachReport.domainEmailsThisPeriod
    },
    section: 'nonSystemUserBreaches'
  }),
  getBreachedServiceSection({
    trOpt,
    section: 'newBreachedServices',
    services: breachReport?.newBreachedServices
  }),
  getBreachedServiceSection({
    trOpt,
    section: 'existingBreachedServices',
    services: breachReport?.existingBreachedServices
  })
])
