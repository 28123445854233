import React, { useState, useMemo, useCallback } from 'react'
import { useQuery, useMutation } from 'react-apollo'
import { Tag } from 'antd'
import I18n from 'i18n-js'

import { SettingsContainer } from '../Settings/common'
import { GET_ME_2FA, UPDATE_2FA_ENABLED } from '../Queries/Users'
import { Loader } from '../common'
import EnableQR from './EnableQR'
import QRIntro from './QRIntro'
import RecoveryKeys from './RecoveryKeys'
import QRCode from './QRCode'
import TwoFactorAuthSettings from './TwoFactorAuthSettings'
import IntercomHeader from '../IntercomHeader'
import { showErrors } from '../../helpers'

const ChangeSecurity = () => {
  // Current Step State
  // twoFactorSettings - MFA settings screen when MFA is enabled on current admin user's account
  // disabled - MFA settings screen when MFA is disabled on current admin user's account
  // intro - Introduction screen for MFA setup. It can also be used to redo setup.
  // recovery - Recovery code step of MFA setup which can be used post setup to view or regenerate their codes
  // QRCode - MFA setup step where admin users scan QR code and enter an auth code to complete authenticator app setup.
  const [currentStep, setCurrentStep] = useState('')
  const { data: meData, loading: meLoading, refetch: getUserData } = useQuery(GET_ME_2FA, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data?.me?.user?.is2FAEnabled) {
        setCurrentStep('twoFactorSettings')
      } else {
        setCurrentStep('disabled')
      }
    },
    onError: e => {
      console.error('ChangeSecurity GET_ME_2FA Error', e)
      showErrors(e, I18n.t('common.somethingWentWrong'))
    }
  })
  const [update2FAEnabled] = useMutation(UPDATE_2FA_ENABLED, {
    onCompleted: useCallback((result) => {
      if (result?.update2FAEnabled) {
        setCurrentStep('twoFactorSettings')
      } else {
        setCurrentStep('disabled')
      }
      getUserData()
    }, [getUserData]),
    onError: e => showErrors(e, I18n.t('common.somethingWentWrong'))
  })
  const callUpdate2FAEnabled = useCallback(
    async is2FAEnabled =>
      update2FAEnabled({
        variables: {
          is2FAEnabled
        }
      }),
    [update2FAEnabled]
  )
  const enable2FA = useCallback(async () => callUpdate2FAEnabled(true), [callUpdate2FAEnabled])
  const disable2FA = useCallback(async () => callUpdate2FAEnabled(false), [callUpdate2FAEnabled])

  const is2FAEnabled = useMemo(() => meData?.me?.user?.is2FAEnabled, [meData])

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <IntercomHeader Size='h1' id='account-settings-two-factor-auth-header'>{I18n.t('twoFactorAuthentication', { scope: 'signIn' })}</IntercomHeader>
        {currentStep === 'twoFactorSettings' && <div><Tag icon='right' color='green'>Enabled</Tag></div>}
      </div>
      <SettingsContainer>
        {meLoading && <Loader />}
        {(currentStep === 'twoFactorSettings') && (
          <TwoFactorAuthSettings {...{ setCurrentStep, disable2FA }} />
        )}
        {(currentStep === 'disabled') && (
          <EnableQR {...{ setCurrentStep }} />
        )}
        {(currentStep === 'intro') && (
          <QRIntro {...{ is2FAEnabled, disable2FA, setCurrentStep }} />
        )}
        {(currentStep === 'recovery') && (
          <RecoveryKeys {...{ is2FAEnabled, getUserData, setCurrentStep }} />
        )}
        {currentStep === 'QRCode' && (
          <QRCode {...{ setCurrentStep, enable2FA, disable2FA }} />
        )}
      </SettingsContainer>
    </>
  )
}

export default ChangeSecurity
