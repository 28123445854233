import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Layout, Divider, Card, Row, Col, Spin, Progress } from 'antd'
import { Query } from 'react-apollo'
import _get from 'lodash/get'
import I18n from 'i18n-js'
import { GET_PENDING_COURSES } from '../Queries/LearnerCourses'
import { FontAwesomeIcon } from '../common'
import { DEFAULT_LANGUAGE } from '../../constants/languages'
import DownloadCertificateBtn from '../common/DownloadCertificateBtn.js'
import { renderParagraphsFragmentFromArray } from '../../helpers'
import RedirectToPortalButton from '../EndUserPortal/RedirectToPortalButton'

const { Content } = Layout
const END_USER_PORTAL = window.__USECURE_CONFIG__.REACT_APP_END_USER_PORTAL === 'true'

export const LearnerCourseFinishedContainer = styled.div`
  margin: 40px auto;
  width: 90%;
`
export const LearnerCourseFinishedLayout = styled(Layout)`
  padding: 24px 0;
  background: #fff;
  text-align: center;
`

export const LearnerCourseFinishedContent = styled(Content)`
  padding: 0 24px;
  min-height: 280;
`

export const LearnerCourseFinishedProgress = styled(Progress)`
  margin: 20px 0;
`

export const PendingCol = styled(Col)`
  margin-top: 20px;
`
const pendingCard = ({ hideBody, ...rest }) => (<Card {...rest} />)

export const PendingCard = styled(pendingCard)`
  text-align: left;

  .ant-card-body {
    display: ${({ hideBody }) => hideBody ? 'none' : 'block'};
  }
  &> a {
    margin-left: 5px;
  }
`

class LearnerCourseFinished extends Component {
  getLocaleCourseValue (course, field) {
    const { locale, preferredLocale, browserLocale } = this.props
    return _get(course, [field, locale],
      _get(course, [field, preferredLocale],
        _get(course, [field, browserLocale],
          _get(course, [field, DEFAULT_LANGUAGE])
        )
      )
    )
  }

  renderPendingCourses () {
    const { learnerId, pendingCourses } = this.props

    if (pendingCourses.length === 0) {
      return null
    }

    return (
      <Row>
        <Divider><h2>{I18n.t('learnerCourse.remainingCourses')}</h2></Divider>
        <Row gutter={16}>
          {
            pendingCourses.map((course, index) => (
              <PendingCol xs={{ span: 24 }} lg={{ span: 8 }} key={index}>
                <PendingCard
                  title={this.getLocaleCourseValue(course, 'name')}
                  extra={
                    <a href={`/learner/${learnerId}/course/${course.id}`}>
                      {<p>{I18n.t('learnerCourse.takeCourse')}</p>}
                    </a>
                  }
                >{this.getLocaleCourseValue(course, 'description')}
                </PendingCard>
              </PendingCol>
            ))
          }
        </Row>
      </Row>
    )
  }

  render () {
    const { loading, courseTitle, courseIcon, pendingCourses, score, isGapAnalysis, learnerName, finishDate, theme, companyId, preview, endUserPortalEnabled, endUserSessionType } = this.props

    return (
      <LearnerCourseFinishedContainer>
        <LearnerCourseFinishedLayout>
          <LearnerCourseFinishedContent>
            <Spin spinning={loading}>
              <Row>
                {isGapAnalysis
                  ? (
                    <div>
                      <p>{I18n.t('learnerCourse.gapAnalysisTitle')}</p>
                      <p>{I18n.t('learnerCourse.thankYou')}</p>
                      <LearnerCourseFinishedProgress type='circle' percent={100} />
                    </div>
                  )
                  : (
                    <div>
                      {
                        courseIcon
                          ? <h1><FontAwesomeIcon icon={courseIcon} /> {courseTitle}</h1>
                          : <h1>{courseTitle}</h1>
                      }
                      <p>{I18n.t('learnerCourse.congratulations')}</p>
                      {typeof score === 'number'
                        ? (
                          <>
                            <p>{I18n.t('learnerCourse.score')}</p>
                            <LearnerCourseFinishedProgress type='circle' percent={score} format={(percent) => `${percent}%`} />
                          </>
                        )
                        : null}
                      {pendingCourses.length <= 0
                        ? <p>{I18n.t('learnerCourse.closeWindow')}</p>
                        : null}
                    </div>
                  )}
              </Row>
              {!isGapAnalysis && <DownloadCertificateBtn logoSrc={theme.appThemeLogo} ribbonColour={theme.primary} course={{ courseTitle, dateOfCompletion: finishDate, scorePercentage: score, presentedToName: learnerName }} />}
              {END_USER_PORTAL && endUserPortalEnabled && !preview && endUserSessionType !== 'limited' && (
                <Row>
                  <Divider><h2>{I18n.t('title', { scope: 'endUserPortal.whatIsThePortal' })}</h2></Divider>
                  <Row gutter={16}>
                    {renderParagraphsFragmentFromArray(I18n.t('description', { scope: 'endUserPortal.whatIsThePortal' }))}
                    <RedirectToPortalButton companyId={companyId} buttonText='goToPortal' />
                  </Row>
                </Row>
              )}
              {this.renderPendingCourses()}
            </Spin>
          </LearnerCourseFinishedContent>
        </LearnerCourseFinishedLayout>
      </LearnerCourseFinishedContainer>
    )
  }
}

LearnerCourseFinished.propTypes = {
  loading: PropTypes.bool,
  learnerId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  courseTitle: PropTypes.string,
  score: PropTypes.number,
  pendingCourses: PropTypes.arrayOf(PropTypes.object),
  isGapAnalysis: PropTypes.bool,
  preview: PropTypes.bool
}

LearnerCourseFinished.defaultProps = {
  loading: false,
  learnerId: null,
  courseTitle: null,
  score: null,
  pendingCourses: [],
  isGapAnalysis: false,
  preview: false,
  learnerName: null
}

const LearnerCourseFinishedQuery = (props) => {
  const { learnerId, locale, preview } = props
  return (
    <Query query={GET_PENDING_COURSES} variables={{ id: learnerId }} skip={preview}>
      {
        ({ loading, error, data = {} }) => {
          const { pendingLearnerCourses: pendingCourses = [] } = data

          return (
            <LearnerCourseFinished {...{ loading, pendingCourses, locale, ...props }} />
          )
        }
      }
    </Query>
  )
}

export default LearnerCourseFinishedQuery
